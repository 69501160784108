@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url(Dosis.woff) format('woff');
}

a {
  text-decoration: none;
  color: rgb(255,255,255);
}

h1 {
  color: rgb(255,255,255);
}

h2 {
  color: rgb(255,255,255);
}

body {
  color: #FFF;
  font-family: Dosis;
  height: 100%;
  margin: 0;
  padding: 3%;
  background-color: #3961D4;
}

html {
  height: 100%;
}

#footer {
  position: fixed;
  padding-bottom: 10px;
  bottom: 0px;
  width: 100%;
  background-color: #3961D4;
}

.center {
  margin: 0 auto;
  display: table;
  text-align: center;
}

.content {
  max-width: 600px;
  padding: 10px;
  margin-bottom: 40px;
  padding-bottom: 80px;
  margin: 0 auto;
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  display: table;
}

.textpart {
  margin: 0 auto;
  width: 100%;
  padding-bottom: 30px;
  display: table;
  text-align: center;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 256px;
}

.badge {
  /* width: 100px; */
  height: 50px;
  margin-right: 10px;
}
